import styled from '@emotion/styled';
import { flexColContainerStyles, HideOnMobile } from 'commonStyles';
import {
  DOM_STATE,
  TEXT_ALIGN,
  TEXT_TYPE,
  TSHIRT_SIZE,
} from 'components/constants';
import Cta from 'components/Cta';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_FLEX_WRAP,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  FONT_FAMILY,
  getThemeBrandedColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeMainColor,
  ITheme,
} from 'theme';
import { themev2 } from 'theme-v2';
import hideable from 'utils/hideable';

export const StyledFeaturesContainer = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  margin: '0 auto',
  marginTop: getThemeGutter(props, TSHIRT_SIZE.XL),
  [MEDIA_QUERIES.TABLET]: {
    marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
  },
}));

export const StyledList = styled.ul({
  listStyle: 'none',
  display: CSS_DISPLAY.FLEX,
  flexWrap: CSS_FLEX_WRAP.WRAP,
  padding: 0,
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  },
});

export const ListItem = styled.li<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  marginRight: getThemeGutter(props, TSHIRT_SIZE.S),
  padding: 0,
  color: getThemeMainColor(props),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
  fontWeight: 400,
  maxHeight: '24px',
}));

export const CheckmarkWrapper = styled.span({
  width: '16px',
  height: 'auto',
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  margin: '8px',
  marginLeft: '0',
});

export const StyledWrapper = styled.section<ITheme>((props) => ({
  background: themev2.colors.black.S900,
  width: '100%',
  padding: `${getThemeGutter(props, TSHIRT_SIZE.L)} 20px`,
}));

export const StyledContainer = styled.div<ITheme>((props) => ({
  margin: '0 auto',
  maxWidth: '1024px',
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: '1fr 360px',
  alignItems: CSS_ALIGN.CENTER,
  columnGap: getThemeGutter(props, TSHIRT_SIZE.L),
  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(2, auto)',
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
    rowGap: 'unset',
    margin: '0 auto',
  },
}));

export const StyledTextContainer = styled.div<ITheme & { isReviewOn?: string }>(
  ({ color, isReviewOn, ...props }) => ({
    ...flexColContainerStyles,
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XXXL),
    h1: {
      marginTop: isReviewOn
        ? getThemeGutter(props, TSHIRT_SIZE.XL)
        : getThemeGutter(props, TSHIRT_SIZE.XXS),
      marginBottom: getThemeGutter(props),
      color: color || getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
      [MEDIA_QUERIES.PHONE_PORTRAIT]: {
        marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
      },
    },
    p: {
      marginBottom: '27px',
      color: getThemeMainColor(props),
      a: {
        color: color || getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
        [DOM_STATE.HOVER]: {
          textDecoration: 'underline',
        },
      },
      ':last-of-type': {
        marginBottom: 0,
      },
    },
    [MEDIA_QUERIES.TABLET]: {
      alignItems: CSS_ALIGN.CENTER,
      gridRow: 2,
      textAlign: TEXT_ALIGN.CENTER,
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      h4: {
        marginBottom: '28px',
      },
    },
  })
);

export const Title = styled.div<ITheme & { isReviewOn?: string }>(
  ({ color, isReviewOn, ...props }) => ({
    'h1, h2, h3': {
      textAlign: TEXT_ALIGN.LEFT,
      marginTop: isReviewOn
        ? getThemeGutter(props, TSHIRT_SIZE.S)
        : getThemeGutter(props, TSHIRT_SIZE.XXS),
      marginBottom: getThemeGutter(props),
      color: color || getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
      [MEDIA_QUERIES.TABLET]: {
        textAlign: TEXT_ALIGN.CENTER,
      },
      [MEDIA_QUERIES.PHONE_PORTRAIT]: {
        marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
        textAlign: TEXT_ALIGN.CENTER,
      },
    },
  })
);

export const Subtitle = styled.div<ITheme>(({ color, ...props }) => ({
  p: {
    fontFamily: getThemeFontFamily(props),
    marginBottom: '27px',
    color: getThemeMainColor(props),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
    fontWeight: 400,
    ':last-of-type': {
      marginBottom: 0,
      [MEDIA_QUERIES.PHONE_PORTRAIT]: {
        marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
      },
    },
    a: {
      color: color || getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
      fontFamily: getThemeFontFamily(props),
      [DOM_STATE.HOVER]: {
        textDecoration: 'underline',
      },
    },
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    'h2, h3, h4': {
      marginBottom: '28px',
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    p: {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.M),
    },
  },
}));

export const CtaContainer = styled.div<ITheme>((props) => ({
  marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
  display: CSS_DISPLAY.FLEX,
  a: {
    margin: 0,
  },
  [MEDIA_QUERIES.TABLET]: {
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '87%',
    minWidth: '460px',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    width: '100%',
    minWidth: 'unset',
    margin: 0,
  },
}));

export const CtaPricing = styled(Cta)<ITheme>((props) => ({
  width: '220px',
  height: '52px',
  fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
  fontWeight: 500,
  textAlign: TEXT_ALIGN.CENTER,
  margin: 0,
  [MEDIA_QUERIES.TABLET]: {
    width: '220px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '220px',
    maxWidth: '220px',
    marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    maxWidth: '100%',
  },
}));

export const CtaStart = styled(CtaPricing)<ITheme>((props) => ({
  marginRight: getThemeGutter(props),
}));

export const ImageContainer = styled.div<{ isPointerVisible?: boolean }>(
  ({ isPointerVisible }) => ({
    margin: 'auto',
    position: CSS_POSITION.RELATIVE,
    cursor: isPointerVisible ? 'pointer' : 'default',
    ...HideOnMobile,
  })
);

export const HideableImageContainer = hideable(ImageContainer);
export const StyledReviews = styled.div<ITheme & { isStarsAdded: boolean }>(
  ({ isStarsAdded, ...props }) => ({
    display: CSS_DISPLAY.FLEX,
    alignItems: CSS_ALIGN.CENTER,
    p: {
      width: 'auto',
      margin: 0,
      color: getThemeMainColor(props),
      fontSize: isStarsAdded
        ? getThemeFontSize(props, TEXT_TYPE.H5)
        : getThemeFontSize(props, TEXT_TYPE.H4),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H5),
      fontWeight: 500,
      fontFamily: isStarsAdded
        ? getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL)
        : getThemeFontFamily(props, FONT_FAMILY.MAIN),
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      flexDirection: isStarsAdded
        ? CSS_FLEX_DIRECTION.COLUMN
        : CSS_FLEX_DIRECTION.ROW,
      justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    },
  })
);

export const StyledImageWrapper = styled.div<
  ITheme & { isStarsAdded: boolean }
>(({ isStarsAdded, ...props }) => ({
  marginRight: isStarsAdded ? getThemeGutter(props, TSHIRT_SIZE.S) : '5px',
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginRight: isStarsAdded ? 0 : '5px',
    marginBottom: isStarsAdded ? getThemeGutter(props) : 0,
  },
}));

export const StyledPlayIconWrapper = styled.div({
  position: CSS_POSITION.ABSOLUTE,
  display: CSS_DISPLAY.FLEX,
  width: '60px',
  height: '60px',
  top: '50%',
  left: 0,
  right: 0,
  margin: '0 auto',
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  border: '1px solid hsla(0,0%,100%,.2)',
  borderRadius: '100%',
  backgroundColor: 'rgba(7,16,24,.8)',
  transition: 'transform .1s ease-in-out',
  zIndex: 2,
  [DOM_STATE.HOVER]: {
    transform: 'scale(1.2)',
  },
});

export const StyledIconCloseWrapper = styled.div({
  marginTop: '20px',
  position: CSS_POSITION.ABSOLUTE,
  top: 0,
  right: 0,
  width: '64px',
  opacity: 0.8,
  cursor: 'pointer',
  [DOM_STATE.HOVER]: {
    opacity: 1,
  },
});
